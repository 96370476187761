@import "base/variables";

ul li {
  list-style-type: none;
}

.card-widget .card-widget-body a {
  text-decoration: none;
}

.line-height-sm {
  line-height: 0.8;
}

.collapse {
  transition: height 0.3s;
}

.collapse__list--closed {
  height: 0;
  overflow: hidden;
}

.errorMessage {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.css-26l3qy-menu {
  z-index: 3 !important;
}

.color {
  &--error {
    color: $danger;
  }

  &--secondary {
    color: $secondary;
  }
}

.h-30px {
  height: 30px;
}

.w-30px {
  width: 30px;
}

.mw-400px {
  max-width: 400px;
}

.Table__row {
  &:hover {
    background-color: #d9d9d9;
  }
}

.blur-bg {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    opacity: 0.1;
    position: absolute;
    border-radius: 0.5rem;
    z-index: -1;
  }
}

.TransactionDesign {
  table {
    border-spacing: 0 0.5rem;
    border-collapse: unset;
  }

  .max-w-\[80\%\] {
    max-width: 80%;
  }

  .md\:max-w-\[200px\] {
    max-width: 200px;
  }

  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  font-size: 16px;

  td {
    font-size: 16px;
  }

  .content_copy {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
  }

  &-1 {
    overflow: hidden;
    background-color: #191919;
    color: #ffffff;

    .bg-accent {
      background-color: rgb(78 164 228);
    }
  }

  &-2 {
    background-color: #ffffff;
    color: #000000;

    .bg-accent {
      background-color: rgb(78 164 228);
    }
  }

  &-3 {
    background: linear-gradient(249.73deg, #d6311a 1.02%, #be0639 17.13%, #4d003c 67.46%, #4d0037 98.51%);
    color: #5720B5;

    .bg-accent {
      background-color: #DA153A;
    }
  }

  &-4 {
    background-color: #373035;
    color: #FFFFFF;

    .bg-accent {
      background: linear-gradient(97.76deg, #F08A20 -40.95%, #F0BD1F 45.4%, #EF4523 138.39%);
    }
  }
}

.text-red-600 {
  color: rgb(220 38 38);
}

.text-yellow-600 {
  color: rgb(202 138 4);
}

.Stain {
  position: relative;
  span {
    border-radius: 50%;
    position: absolute;
    filter: blur(50px);
  }
}

.react-datepicker-wrapper {
  display: block;
}
